var AddressDisplayer;

(function (w, $) {
  'use strict';
  /**
   * Used to display addresses returned by Canada Post Address Complete services
   * @param lookupFieldId {string}
   * @constructor
   */
  AddressDisplayer = function (lookupFieldId) {
    /** @type {string} */
    this.lookupFieldId = lookupFieldId;

    /** @enum {string} */
    this.states = {
      FIND: 'Find',
      FIND_MORE: 'FindMore',
      RETRIEVE: 'Retrieve'
    };

    /** @enum {string} */
    this.fields = {
      STREET: 'street',
      UNIT: 'unit',
      CITY: 'city',
      POSTAL_CODE: 'postalcode',
      PROVINCE: 'province'
    };

    /** @type {AddressDisplayer.states} */
    this.state = this.states.FIND;
  };

  /**
   * @param fieldType {string}
   * @param value
   * @returns {AddressDisplayer}
   */
  AddressDisplayer.prototype.displayOne = function (fieldType, value) {
    var element = $(sprintf("[address-parent='%s'][address-field='%s']", this.lookupFieldId, fieldType));
    if (!element) {
      return this;
    }
    if (element.hasClass('typeahead')) {
      element.typeahead('val', value);
    } else {
      element.val(value);
    }

    //Some elements may have events attached
    element.trigger('change');
    element.trigger('blur');
    element.trigger('focus');

    //Give focus back to parent element
    $('#' + this.lookupFieldId).trigger('focus');

    return this;
  };

  /**
   * @param result {Object} object returned by AddressComplete
   * @returns {AddressDisplayer}
   */
  AddressDisplayer.prototype.display = function (result) {
    return this.displayOne(this.fields.STREET, (result.BuildingNumber || result.Street) ? result.BuildingNumber + ' ' + result.Street : result.Line1)
      .displayOne(this.fields.UNIT, result.SubBuilding)
      .displayOne(this.fields.CITY, result.City)
      .displayOne(this.fields.POSTAL_CODE, result.PostalCode)
      .displayOne(this.fields.PROVINCE, 'CAN' + result.ProvinceCode);
  };

  /**
   * @param result {Object} object returned by AddressComplete
   * @returns {AddressDisplayer}
   */
  AddressDisplayer.prototype.displayResult = function (result, countryCode) {
    var postalCode = 'USA' === countryCode ? result.PostalCode.substr(0, 5) : result.PostalCode;

    return this.displayOne(this.fields.STREET, (result.BuildingNumber || result.Street) ? result.BuildingNumber + ' ' + result.Street : result.Line1)
      .displayOne(this.fields.UNIT, result.SubBuilding)
      .displayOne(this.fields.CITY, result.City)
      .displayOne(this.fields.POSTAL_CODE, postalCode)
      .displayOne(this.fields.PROVINCE, countryCode + result.ProvinceCode);
  };

  /**
   * @returns {AddressDisplayer.states}
   */
  AddressDisplayer.prototype.getState = function () {
    return this.state;
  };

  /**
   * @param desiredState {AddressDisplayer.states}
   * @returns {AddressDisplayer}
   */
  AddressDisplayer.prototype.changeState = function (desiredState) {
    if (desiredState === this.states.FIND && this.state === this.states.FIND) {
      this.state = this.states.FIND_MORE;
    } else {
      this.state = desiredState;
    }

    return this;
  };

  /**
   * @returns {AddressDisplayer}
   */
  AddressDisplayer.prototype.resetState = function () {
    this.state = this.states.FIND;
    return this;
  };
}(window, window.jQuery));
