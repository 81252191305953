// Librairie javascript pour la cybermétrie.
// Inclure juste après l'ouverture du <body> (NE PAS INCLURE DANS UN DIV OU UN AUTRE ÉLÉMENT.)
// Exemple : <script src="cybermetrie.js" defer></script>

var dataLayer = dataLayer || []; // dataLayer pour communiquer avec Google Tag Manager
var mwNumContenantGoogle = mwNumContenantGoogle || 'UNDEFINED_ID';

// Initialiser les variables.
function Cybermetrie() {
  this.gtmActive = true;
  this.gtmContainerID = mwNumContenantGoogle;
}

Cybermetrie.prototype.initialiseGTM = function () {
  try {
    // Nous avons besoin document.querySelectorAll pour valider si nous devons activer la cybermetrie.
    if (document.querySelectorAll) {
      // Activer la prise de mesure web seulement si la variable de controle le permet et
      // si la mesure n'est pas desactivé par l'attribut data-mw-page-blocked ou par la QueryString ?mwIgnore. (Exemple, si nous voulons desactiver)
      if (this.gtmActive && document.querySelectorAll("[data-mw-page-blocked]").length == 0 && document.URL.substring(document.URL.indexOf("?") + 1).toLowerCase().indexOf("mwignore") < 0) {
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
            var f = d.getElementsByTagName('link')[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = '//www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          }
        )(window, document, 'script', 'dataLayer', escape(this.gtmContainerID));
      }
    }
  } catch (e) {
    dataLayer.push({
      'event': 'erreur',
      'category': 'Erreur',
      'action': 'GTM',
      'label': ('Erreur lors du chargement de la librairie : ' + e.name + " : " + e.message)
    });
  }
};

var cybermetrie = new Cybermetrie();
cybermetrie.initialiseGTM();
