// Librairie javascript pour onetrust.
// Inclure avant tout script dans le <head>

var prodDisnatHost = [
  'ouverturedecompte.disnat.com',
  'accountopening.disnat.com'
];

var prodInternalVMDHost = [
  'ouverturedecompte.vmd.ca'
];

var prodInternalDesjHost = [
  'ouverturedecompte-priv-disnat.desjardins.com',
];

// Initialiser les variables.
function OneTrust() {
  this.oneTrustActive = true;
  if (prodDisnatHost.includes(location.hostname)) {
    this.oneTrustId = '20960395-2056-4927-aa56-a6839db5aa4e';
  } else if (prodInternalVMDHost.includes(location.hostname)) {
    this.oneTrustId = '8623b905-0469-49d4-80ae-6cbec863a83f';
  } else if (prodInternalDesjHost.includes(location.hostname)) {
    this.oneTrustId = 'e9872dc2-14af-4d30-b0e9-01b95a0b9ab1';
  } else {
    this.oneTrustId = '20960395-2056-4927-aa56-a6839db5aa4e-test';
  }
}

function generateOneTrustScript(id) {
  var script = document.createElement('script');
  script.id = 'consentementCookiesScript'
  script.src = 'https://static.desjardins.com/fw/cookie/scriptCookie.js';
  script.setAttribute('data-domain-script', id);
  return script;
}

function generateDynatraceConsentScript() {
  var script = document.createElement('script');
  script.src = 'https://static.desjardins.com/fw/cookie-dynatrace/consentementDynatrace.js';
  return script;
}

OneTrust.prototype.init = function () {
  try {
    if (document.querySelectorAll) {
      if (this.oneTrustActive) {
        (function (d, id) {
            var f = d.getElementsByTagName('script')[0];
            var oneTrustScript = generateOneTrustScript(id);
            var dynatraceScript = generateDynatraceConsentScript();
            f.parentNode.insertBefore(oneTrustScript, f);
            f.parentNode.insertBefore(dynatraceScript, f);
          }
        )(document, this.oneTrustId);
      }
    }
  } catch (e) {
  }
};

var oneTrust = new OneTrust();
oneTrust.init();
