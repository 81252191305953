/**
 * fondation.logger.js
 *
 * browser logging facility
 *
 * @require underscore, moment
 */
(function __define_foundation_logger_ns(_, moment, console, ns) { 'use strict';

    /**
     * The log message template
     */
    var LOG_MESSAGE_TMPL = _.template('<%= timestamp %>: <%= owner %>: <%= message %>');

    /**
     * Log level constants
     */
    var LOG_LEVEL = {
        INFO: 'info',
        WARNING: 'warn',
        ERROR: 'error',
        DEBUG: 'debug'
    };

    /**
     * Aliases for log levels
     */
    var LOG_LEVEL_ALIASES = {
        info: ['log'],
        warn: ['warning'],
        error: ['exception']
    };

    /**
     * Create a logger function which will prefix it's owner name in every logged message
     * The returned function has the following signature:
     *
     * function (level, message, *additional) { ... }
     *
     * @param owner {String} the owner of this logger method
     * @param useUniqueId {boolean} append a uniqe id to the owner name
     * @return {Function} logger function
     */
    var fnLogger = function __foundation_logger_ns_logger (owner, useUniqueId) {

        if (!console) {
            return _.noop;
        }

        var _useUniqueId = false;

        if (arguments.length >= 2) {
            _useUniqueId = useUniqueId;
        }

        var ownerString = _useUniqueId ? _.uniqueId((owner || 'anonymous') + '-') : owner || 'anonymous';

        // argument format: [level, message, ...]
        var returnedLogFn = function () {
            var args = _.toArray(arguments);

            _.defer(function () {
                var level = args.shift(),
                    payload = args.shift(),
                    isObjectPayload = _.isObject(payload),
                    loggerFn = console[level] || console.log,
                    message = LOG_MESSAGE_TMPL({
                        owner: ownerString,
                        message: isObjectPayload ? '' : payload,
                        timestamp: moment().format('DD/MM/YYYY HH:mm:ss.SSS')
                    });

                if (isObjectPayload) {
                    args.unshift(payload);
                }

                args.unshift(message);

                loggerFn.apply(console, args);
            });
        };

        // extend the logger function with shortcuts to itself: info, error, warning, debug
        _.each(LOG_LEVEL, function (levelName) {

            if (levelName == LOG_LEVEL.DEBUG) {
                // honor ns.log.debugEnabled flag
                returnedLogFn[levelName] = function () {

                    if (ns.log.debugEnabled) {
                        var args = _.toArray(arguments);
                        args.unshift(LOG_LEVEL.DEBUG);

                        returnedLogFn.apply(returnedLogFn, args);
                    }
                };
            }
            else {
                returnedLogFn[levelName] = _.partial(returnedLogFn, levelName);

                if (LOG_LEVEL_ALIASES[levelName]) {
                    _.each(LOG_LEVEL_ALIASES[levelName], function (aliasName) {
                        returnedLogFn[aliasName] = returnedLogFn[levelName];
                    });
                }
            }
        });

        return returnedLogFn;
    };

    // create a root logger which can be used everywhere
    var rootLogger = fnLogger('VMD', false);

    // Expose public logging API
    _.extend(ns, {
        /**
         * .log namespace
         *
         * includes utility functions to log to the brower's console
         * @namespace
         */
        log: {
            /**
             * log a message to the browser's console with level 'info'
             * @param {string} message
             * @type {function}
             */
            info: _.partial(rootLogger, LOG_LEVEL.INFO),

            /**
             * @alias info
             */
            log: _.partial(rootLogger, LOG_LEVEL.INFO),

            /**
             * log a message to the browser's console with level 'warn'
             * @param {string} message
             * @type {function}
             */
            warning: _.partial(rootLogger, LOG_LEVEL.WARNING),

            /**
             * @alias warning
             */
            warn: _.partial(rootLogger, LOG_LEVEL.WARNING),

            /**
             * log a message to the browser's console with level 'error'
             * @param {string} message
             * @type {function}
             */
            error: _.partial(rootLogger, LOG_LEVEL.ERROR),

            /**
             * log a message to the browser's console with level 'debug'
             * @param {string} message
             * @type {function}
             */
            debug: function () {
                if (this.debugEnabled) {
                    var args = _.toArray(arguments);
                    args.unshift(LOG_LEVEL.DEBUG);

                    rootLogger.apply(rootLogger, args);
                }
            },

            /**
             * Create a logger function with the specified owner.
             *
             * The returned function expects these parameters:
             *
             *     level: {String} see ns.log.level for valid values
             *     message: {String} the message to log
             *     varargs: {Object|Number|String} any additional arguments will be appended to the logged entry
             *
              * @param owner {String} the owner of this logger method
              * @return {Function} logger function
              * @type {function}
             */
            logger: fnLogger,

            /**
             * log level enum:
             *
             *   INFO, WARNING, ERROR, DEBUG
             */
            level: LOG_LEVEL,

            /**
             * Used to toggle debugging messages. When false, all calls to debug() will be suppressed.
             * @type {boolean}
             */
            debugEnabled: window.location.search.indexOf('JSDEBUG') !== -1
        }
    });

}(_, moment, console || {}, (function () {
    if (!window.VMD) {
        window.VMD = {};
    }

    return window.VMD;
}())));
